import { Link, navigate } from 'gatsby';
import MenuLinkContent, {
  MenuLinkContentProps
} from 'components/molecules/nav/MenuLinkContent';

import { Container } from 'components/atoms/layout/Container';
import { InterfaceText } from 'components/atoms/typography';
import { LinkGetProps } from '@reach/router';
import LogOutIcon from '@carbon/icons-react/es/logout/24';
import NeedHelp from 'components/molecules/support/NeedHelp';
import React from 'react';
import Select from 'components/molecules/forms/select';
import UserInitial from 'components/molecules/account/userInitial';
import { makeClassNames } from 'lib/util';
import { useCustomer, useLogOut } from 'service/hooks/session';

export const linkAccountClassNames = [
  'flex',
  'items-center',
  'px-2',
  'w-full',
  'transition-colors',
  'duration-150',
  'ease-in-out',
  'focus:outline-none',
  'focus-visible:ring',
  'focus-visible:ring-secondary',
  'focus-visible:ring-opacity-50'
];

export const linkAccountInactiveClassNames = [
  'hover:bg-secondary',
  'hover:stroke-1',
  'hover:font-semibold',
  'stroke-0'
];

export type AccountNavigationProps = {
  links: Array<MenuLinkContentProps & { href: string; current: boolean }>;
};

const containerClassNames = [
  'hidden',
  'h-full',
  'w-full',
  'flex-col',
  'justify-between',
  'bg-pearl-300',
  'lg:flex'
];

const AccountNavigation: React.FC<AccountNavigationProps> = ({ links }) => {
  const { data: user } = useCustomer();
  const { mutate: logOut } = useLogOut();

  if (!user) {
    return <div className={makeClassNames(containerClassNames)} />;
  }

  const { email, firstName, surname1, surname2 } = user;

  // firstName = 'Juan';
  // surname1 = 'JimenezJimenezJimenezJimenezJimenez';

  const profileName = firstName
    ? [firstName, surname1, surname2].filter(Boolean).join(' ')
    : email;

  const isActive = ({ isCurrent }: LinkGetProps) => {
    const additionalClassnames = isCurrent
      ? ['bg-pearl-500', 'cursor-default', 'font-bold', 'stroke-1']
      : linkAccountInactiveClassNames;
    const className = makeClassNames(
      linkAccountClassNames,
      additionalClassnames
    );
    return { className };
  };

  const navOptions = links.map(link => ({
    name: link.name,
    active: link.current,
    value: link.href
  }));

  return (
    <>
      <Container className="pt-rhythm3 lg:hidden">
        <Select
          className="w-full"
          label=""
          name="account-navigation"
          options={navOptions}
          onChange={navigate}
        />
      </Container>
      <div className={makeClassNames(containerClassNames)}>
        <div>
          <div className="flex items-center space-x-4 px-4 py-rhythm3">
            <UserInitial />
            <InterfaceText className="overflow-hidden text-ellipsis">
              {profileName}
            </InterfaceText>
          </div>

          <nav>
            {links.map(item => (
              <Link
                key={item.name}
                to={item.href}
                className={makeClassNames(linkAccountClassNames)}
                getProps={isActive}
              >
                <MenuLinkContent Icon={item.Icon} name={item.name} />
              </Link>
            ))}
          </nav>
        </div>

        <div>
          <NeedHelp />
          <button
            onClick={() => logOut({})}
            className={makeClassNames(
              linkAccountClassNames,
              linkAccountInactiveClassNames
            )}
          >
            <MenuLinkContent Icon={LogOutIcon} name="Log Out" />
          </button>
        </div>
      </div>
    </>
  );
};

export default AccountNavigation;
