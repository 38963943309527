import { NavigationMenuQuery } from '@svelte/service/cms/graphql/gql';
import PageContentLayout from './PageContentLayout';
import React from 'react';

type TwoColumnLayoutProps = {
  side: React.ReactNode;
  main: React.ReactNode;
  footerNavigation: NavigationMenuQuery;
};

const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = ({
  footerNavigation,
  side,
  main
}) => {
  return (
    <PageContentLayout
      footerNavigation={footerNavigation}
      className="flex flex-col"
    >
      <div className="flex-1 lg:grid lg:grid-cols-12">
        <div className="col-span-4 xl:col-span-3">{side}</div>
        <div className="col-span-8 xl:col-span-9">{main}</div>
      </div>
    </PageContentLayout>
  );
};

export default TwoColumnLayout;
