import { Heading } from 'components/atoms/typography';
import React from 'react';

type Props = PropsWithChildren & {
  level: 2;
};

const PageHeading: React.FC<Props> = ({ children, level }) => {
  return (
    <Heading level={level} className="border-b-2 border-primary">
      {children}
    </Heading>
  );
};

export default PageHeading;
