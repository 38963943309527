import { Form } from 'react-final-form';
import { FormSubscription } from 'final-form';
import { Input } from 'components/molecules/forms/Input';
import { LiquidSpinner } from 'components/atoms/icons/spinner/Liquid';
import PageHeading from 'components/molecules/layout/PageHeading';
import React from 'react';
import useValidationSchema from 'lib/hooks/useValidationSchema';
import { useCustomer } from 'service/hooks/session';

type FormData = {
  firstName: string;
  surname1: string;
  surname2?: string;
  email: string;
};

const formSubscription: FormSubscription = {
  dirtySinceLastSubmit: true,
  errors: true,
  submitErrors: true,
  submitFailed: true,
  submitting: true,
  touched: true
};

const MyDetails: React.FC = () => {
  const { data: user } = useCustomer();

  if (!user) {
    // NOTE: Because user is loaded with first auth request, this is very unlikely to show
    return (
      <div className="flex justify-center">
        <LiquidSpinner className="h-rhythm9 w-rhythm9 md:h-rhythm10 md:w-rhythm10" />
      </div>
    );
  }

  const initialValues: FormData = {
    email: user.email,
    firstName: user.firstName || '',
    surname1: user.surname1 || ''
    // surname2: ''
  };

  const validate = useValidationSchema<FormData>(Yup => {
    return Yup.object({
      firstName: Yup.string().required('Your first name is required'),
      surname1: Yup.string().required('Your last name is required'),
      // surname2: Yup.string(),
      email: Yup.string()
        .required('An email is required')
        .email('Invalid email address')
    });
  });

  const onSubmit = () => {};

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      subscription={formSubscription}
      render={({
        // errors,
        // submitErrors,
        handleSubmit
        // dirtySinceLastSubmit,
        // submitFailed,
        // submitting
      }) => {
        // const formErrors = errors && !isEmpty(errors) ? errors : submitErrors;

        return (
          <div className="relative">
            <PageHeading level={2}>My Info</PageHeading>
            <form onSubmit={handleSubmit}>
              <div className="grid-cols-2 gap-y-rhythm1 gap-x-rhythm4 lg:grid">
                <Input
                  name="firstName"
                  label="Name"
                  autoComplete="given-name"
                  // TODO: disabled until endpoint is ready
                  disabled
                />

                <Input
                  name="surname1"
                  label="Last Name"
                  autoComplete="family-name"
                  disabled
                />

                <Input
                  name="email"
                  label="Email address"
                  type="email"
                  autoComplete="email"
                  disabled
                />
              </div>

              {/* <div className="mt-rhythm0 w-full pt-rhythm0">
                  <div className="float-right">
                    <SubmitButton
                      text="Update"
                      hasError={submitFailed && !dirtySinceLastSubmit}
                      isSubmitting={submitting}
                      disabled={submitFailed && !dirtySinceLastSubmit}
                    />
                  </div>
                </div>

                <ErrorsSummary
                  headingLevel={2}
                  className="pt-rhythm0"
                  errors={submitFailed ? formErrors : undefined}
                /> */}
            </form>
          </div>
        );
      }}
    />
  );
};

export default MyDetails;
