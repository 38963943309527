import type { CarbonIconType } from '@carbon/icons-react';
import { InterfaceText } from 'components/atoms/typography';
import React from 'react';

export type MenuLinkContentProps = {
  Icon: CarbonIconType;
  name: string;
};

const MenuLinkContent: React.FC<MenuLinkContentProps> = ({ Icon, name }) => {
  return (
    <>
      <div className="flex h-12 w-12 shrink-0 items-center justify-center">
        <Icon
          aria-hidden="true"
          className="stroke-primary-900 text-primary-900"
        />
      </div>
      <div className="ml-4">
        <InterfaceText>{name}</InterfaceText>
      </div>
    </>
  );
};

export default MenuLinkContent;
