import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import { InterfaceText } from 'components/atoms/typography';
import React from 'react';
import { makeClassNames } from 'lib/util';

type Props = {
  className?: string;
  address: GqlSchemaWorker.OrderAddressFragment;
  title: string;
};

const UserAddress: React.FC<Props> = ({ className, address, title }) => {
  if (!address) {
    return null;
  }

  return (
    <dl className={makeClassNames(className)}>
      <InterfaceText as="dt" bold>
        {title}
      </InterfaceText>
      <dd className="text-gray-500">
        <InterfaceText className="block">{address.addressee}</InterfaceText>
        <InterfaceText className="block">
          {address.address?.line1}
        </InterfaceText>
        <InterfaceText className="block">
          {address.address?.line2}
        </InterfaceText>
        <InterfaceText className="block">
          {address.address?.administrativeLevel2}
        </InterfaceText>
        <InterfaceText className="block">
          {address.address?.postalCode}
        </InterfaceText>
      </dd>
    </dl>
  );
};

export default UserAddress;
