import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import PageLoading from 'components/molecules/actions/PageLoading';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { canUseDom } from 'lib/util';
import { navigate } from 'gatsby';
import { useRoutePath } from 'state/context/nav';
import { useCustomer } from 'service/hooks/session';

type Props<T> = RouteComponentProps &
  T & {
    component: React.ComponentType<T>;
    redirectPath: string;
  };

function PrivateRoute<T extends Record<string, unknown>>({
  component: Component,
  redirectPath,
  ...rest
}: Props<T>) {
  const { fetching, data: customer } = useCustomer();
  const loginRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.LOGIN);
  const historyState = canUseDom() && history?.state;

  if (fetching) {
    return (
      <div className="flex flex-col items-center justify-center">
        <PageLoading message="" />
      </div>
    );
  }

  if (customer) {
    return React.createElement<T>(Component, rest as RouteComponentProps & T);
  }

  navigate(loginRoute.path, {
    replace: true,
    state: { ...historyState, redirectPath }
  });
  return null;
}

export default PrivateRoute;
