import { AppPageProps, AppSiteNavItem } from 'gatsby/types';
import { Heading, TextSize } from 'components/atoms/typography';
import {
  Redirect,
  RedirectProps,
  RouteComponentProps,
  useLocation
} from '@reach/router';

import AccountNavigation from 'components/organisms/account/Navigation';
import { Container } from 'components/atoms/layout/Container';
import DashboardIcon from '@carbon/icons-react/es/dashboard/24';
import MyDetails from 'components/organisms/account/MyDetails';
import OrderHistory from 'components/organisms/account/order/OrderHistory';
import Overview from 'components/organisms/account/Overview';
import PrimaryLogo from 'components/atoms/icons/logo/primary/icon';
import PrivateRoute from 'components/organisms/nav/PrivateRoute';
import React from 'react';
import ReceiptIcon from '@carbon/icons-react/es/receipt/24';
import { RouterLegacy } from '../react-legacy';
import TwoColumnLayout from 'components/layouts/TwoColumn';
import UserIcon from '@carbon/icons-react/es/user/24';
import { findRouteInTree } from 'lib/navigation/global';
import { useIsAuthenticated } from 'service/hooks/session';
// import { loggers } from 'lib/log';

// const log = loggers.ui;

export { HeadClientOnly as Head } from 'components/atoms/Head';

export type AccountRouterProps = RouteComponentProps & AppPageProps;

const RedirectC = Redirect as unknown as React.FC<
  RouteComponentProps<RedirectProps<unknown>>
>;

const AccountRouter: React.FC<AccountRouterProps> = ({ pageContext }) => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const { appNav, currentRouteKey } = pageContext;

  const baseRoute = findRouteInTree(appNav, currentRouteKey);
  const [orderHistoryRoute, myDetailsRoute] =
    baseRoute.items as AppSiteNavItem[];
  const [orderHistoryPath, myDetailsPath] = (baseRoute.items || []).map(
    route => {
      return route?.path.replace(`${baseRoute.path}/`, '');
    }
  );

  const accountLinks = [
    {
      // Meta-title in strapi is "Account"
      name: 'Overview',
      href: baseRoute.path,
      Icon: DashboardIcon,
      current: location.pathname === baseRoute.path
    },
    {
      name: myDetailsRoute.title,
      href: `${baseRoute.path}/${myDetailsPath}`,
      Icon: UserIcon,
      current: location.pathname === myDetailsRoute.path
    },
    {
      name: orderHistoryRoute.title,
      href: `${baseRoute.path}/${orderHistoryPath}`,
      Icon: ReceiptIcon,
      current: location.pathname === orderHistoryRoute.path
    }
  ];

  const title = accountLinks.find(l => l.current)?.name;

  return (
    <TwoColumnLayout
      footerNavigation={pageContext.footerNavigation}
      side={<AccountNavigation links={accountLinks} />}
      main={
        <Container
          as="main"
          className="relative flex h-full flex-col py-rhythm3"
        >
          <div className="relative flex-1">
            <div className="absolute inset-0 z-0 hidden h-full items-center justify-center lg:flex">
              <PrimaryLogo className="h-96 text-pearl-700 opacity-5" />
            </div>

            {/* NOTE: if changing mobileSize test with title 'Overview' as Trafalgar breaks the word */}
            <Heading level={1} mobileSize={TextSize.DoublePica}>
              {isAuthenticated ? title : 'Loading'}
            </Heading>

            <div className="relative">
              <RouterLegacy basepath={baseRoute.path}>
                <PrivateRoute
                  component={Overview}
                  redirectPath={baseRoute.path}
                  path="/"
                />
                <PrivateRoute
                  component={MyDetails}
                  redirectPath={`${baseRoute.path}/${myDetailsPath}`}
                  path={myDetailsPath}
                />
                <PrivateRoute
                  component={OrderHistory}
                  redirectPath={`${baseRoute.path}/${orderHistoryPath}`}
                  path={orderHistoryPath}
                />
                <RedirectC noThrow default from="\*" to={baseRoute.path} />
              </RouterLegacy>
            </div>
          </div>
        </Container>
      }
    />
  );
};
export default AccountRouter;
