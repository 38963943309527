import {
  InterfaceText,
  InternalLink,
  TextCase,
  TextSize
} from 'components/atoms/typography';

import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import React from 'react';
import { makeClassNames } from 'lib/util';
import { useRoutePath } from 'state/context/nav';

type NeedHelpProps = {
  className?: string;
  bg?: string;
};

const NeedHelp: React.FC<NeedHelpProps> = ({
  className,
  bg = 'bg-primary-100'
}) => {
  const contactUsRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.CONTACT_US);
  return (
    <div className={makeClassNames(className, bg, 'py-3 pl-6 pr-3')}>
      <InterfaceText
        className="block"
        textCase={TextCase.Uppercase}
        size={TextSize.LongPrimer}
        bold
      >
        NEED HELP?
      </InterfaceText>
      <InternalLink to={contactUsRoute.path}>
        <InterfaceText size={TextSize.Brevier}>Send us a message</InterfaceText>
      </InternalLink>
    </div>
  );
};

export default NeedHelp;
