import React, { Suspense, lazy } from 'react';

import type { SelectProps } from './types';
import { loggers } from 'lib/log';
import { makeClassNames } from 'lib/util';

export * from './types';

const log = loggers.ui;

const nativeSelectClassNames = [
  'max-w-full',
  'rounded-md',
  'bg-pearl-100',
  'border',
  'border-pearl-300',
  'font-medium',
  'text-primary-900',
  'shadow-sm',
  'focus:outline-none',
  'focus:ring-1',
  'focus:ring-primary',
  'focus:border-primary',
  'disabled:bg-pearl-300',
  'disabled:shadow-none'
];

const mediumStyles = ['py-1.5', 'leading-5'];
const smallStyles = ['py-1', 'leading-4', 'text-sm'];

export const NativeSelect: React.FC<SelectProps<string | number>> = ({
  name,
  id = name,
  onChange,
  label,
  showLabel = false,
  className,
  options,
  size = 'm',
  ...restProps
}) => {
  const classNamesMerged = makeClassNames(
    nativeSelectClassNames,
    className,
    size === 'm' ? mediumStyles : smallStyles
  );
  const selected = options.find(o => o.active);

  if (!selected) {
    log.error(new Error('No active SelectEnhanced selection'), { options });
    return null;
  }

  return (
    <>
      <label htmlFor={id} className={showLabel ? '' : 'sr-only'}>
        {label}
      </label>
      <select
        id={id}
        name={name}
        onChange={e => onChange(e.target.value)}
        value={selected.value}
        {...restProps}
        className={classNamesMerged}
      >
        {options.map(({ name, value }) => (
          <option key={value || name} value={value || name}>
            {name || value}
          </option>
        ))}
      </select>
    </>
  );
};

const SelectEnhanced = lazy(
  () => import(/* webpackChunkName: "select-enhanced" */ './SelectEnhanced')
);

export const Select: React.FC<SelectProps<string | number>> = props => {
  return (
    <>
      <div className="md:hidden">
        <NativeSelect {...props} />
      </div>

      <div className="hidden md:block">
        <Suspense fallback={<NativeSelect {...props} />}>
          <SelectEnhanced {...props} />
        </Suspense>
      </div>
    </>
  );
};

export default Select;
