import { BodyText } from 'components/atoms/typography';
import ErrorMessageBlock from 'components/molecules/error/ErrorMessageBlock';
import { LiquidSpinner } from 'components/atoms/icons/spinner/Liquid';
import OrderDetails from './OrderDetails';
import React from 'react';
import { useUserOrders } from 'service/hooks/orders';

const OrderHistory: React.FC = () => {
  const { data, fetching, error } = useUserOrders();
  const orders = data?.currentUser.orders;

  if (fetching) {
    return (
      <div className="flex justify-center">
        <LiquidSpinner className="h-rhythm9 w-rhythm9 md:h-rhythm10 md:w-rhythm10" />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorMessageBlock message="We're unable to get your user data. Please try again later." />
    );
  }

  if (!orders?.length) {
    return (
      <div>
        <BodyText>No orders placed</BodyText>
      </div>
    );
  }

  return (
    <div className="space-y-rhythm1 lg:space-y-rhythm4">
      {orders.map(order => (
        <OrderDetails key={order.id} order={order} />
      ))}
    </div>
  );
};

export default OrderHistory;
