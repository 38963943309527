import { List } from 'components/atoms/typography';

import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import React from 'react';
import { useAppNavTree } from 'state/context/nav';
import OrderItemReactified from '@svelte/reactify/order/OrderItemReactified';

type Props = {
  className?: string;
  discount?: GqlSchemaWorker.DiscountFragment | null;
  items: GqlSchemaWorker.OrderItemFragment[];
};

const CompleteOrderItems: React.FC<Props> = ({
  className,
  discount,
  items
}) => {
  const appNavTree = useAppNavTree();

  return (
    <section aria-labelledby="summary-heading" className={className}>
      <h2 id="summary-heading" className="sr-only">
        Order Items
      </h2>

      <div className="divide-y divide-primary-200">
        <List blank withoutSpacing>
          {items.map(item => {
            // HACK: Fix for Decimal not being parsed in order confirmation
            // in graphql server
            if (item.skuId === 33) {
              item.price = 450
            } else if (item.skuId === 34) {
              item.price = 950
            }

            return <OrderItemReactified
              key={item.skuId}
              appNavTree={appNavTree}
              discount={discount}
              name={item.skuName}
              priceIntegerWithDecimals={item.price}
              productId={item.productId}
              qty={item.qty}
            />
          })}
        </List>
      </div>
    </section>
  );
};

export default CompleteOrderItems;
