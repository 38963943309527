import { BodyText, Heading, TextSize } from 'components/atoms/typography';

import ErrorMessageBlock from 'components/molecules/error/ErrorMessageBlock';
import { LinkButton } from 'components/atoms/button';
import { LiquidSpinner } from 'components/atoms/icons/spinner/Liquid';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import OrderDetails from './order/OrderDetails';
import React from 'react';
import { useUserOrders } from 'service/hooks/orders';
import { useRoutePath } from 'state/context/nav';
import { useCustomer } from 'service/hooks/session';

const RecentOrders: React.FC = () => {
  const orderHistory = useUserOrders();

  if (orderHistory.fetching) {
    return (
      <div className="flex justify-center">
        <LiquidSpinner className="h-rhythm8 w-rhythm8 md:h-rhythm9 md:w-rhythm9" />
      </div>
    );
  }

  if (orderHistory.error) {
    return (
      <ErrorMessageBlock message="We're unable to get your user data. Please try again later." />
    );
  }

  const recentOrders = orderHistory.data?.currentUser.orders.slice(0, 2) || [];

  if (!recentOrders.length) {
    return <BodyText>No orders placed</BodyText>;
  }

  return (
    <div className="space-y-rhythm1 lg:space-y-rhythm4">
      {recentOrders.map(order => (
        <OrderDetails compact key={order.id} order={order} />
      ))}
    </div>
  );
};

const Account: React.FC = () => {
  const { data: user } = useCustomer();
  const orderHistoryRoute = useRoutePath(
    MAIN_NAVIGATION_ROUTER_KEY.ORDER_HISTORY
  );
  const orderHistoryQuery = useUserOrders();
  const orderHistory = orderHistoryQuery.data?.currentUser.orders;

  if (!user) {
    return null;
  }

  return (
    <>
      <div className="mb-rhythm3 border-b-2 border-primary md:flex md:items-center md:justify-between">
        <Heading withoutSpacing level={2} mobileSize={TextSize.GreatPrimer}>
          Recent Orders
        </Heading>
        <div className="py-4">
          {orderHistory?.length ? (
            <LinkButton kind="ghost" to={orderHistoryRoute.path} size="s">
              Full List
            </LinkButton>
          ) : null}
        </div>
      </div>

      <RecentOrders />
    </>
  );
};

export default Account;
