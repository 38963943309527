import { BodyText, Heading } from 'components/atoms/typography';

import React from 'react';

const ErrorMessageBlock: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center border border-red-500 bg-red-200 text-red-900">
      <Heading level={2} color="">
        Oops!
      </Heading>
      <BodyText measure="measure-narrow" className="text-center" color="">
        {message}
      </BodyText>
    </div>
  );
};

export default ErrorMessageBlock;
