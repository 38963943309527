import { Heading, InterfaceText, TextSize } from 'components/atoms/typography';

import CompleteOrderItems from 'components/molecules/orders/dashboard/CompleteOrderItems';
import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import OrderAmounts from 'components/molecules/orders/review/Amounts';
import React from 'react';
import UserAddress from 'components/molecules/account/userAddress';
import { makeClassNames } from 'lib/util';

type Props = PropsWithClassName & {
  compact?: boolean;
  order: GqlSchemaWorker.OrderDataFragment;
};

const OrderDetails: React.FC<Props> = ({ className, compact, order }) => {
  const createdDate = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'long'
  }).format(new Date(order.completedAt));

  return (
    <div
      key={order.id}
      className={makeClassNames(
        className,
        'rounded-lg border border-primary bg-pearl-700 bg-opacity-10 pb-rhythm2'
      )}
    >
      {/* Order heading */}
      {compact && (
        <div className="space-y-rhythm0 px-4 pt-rhythm1 lg:px-8 lg:pb-rhythm1 xl:space-y-0">
          <Heading size={TextSize.GreatPrimer} withoutSpacing level={2}>
            {createdDate}
          </Heading>
          <div>
            <InterfaceText size={TextSize.LongPrimer}>Ref: </InterfaceText>
            <InterfaceText italic size={TextSize.LongPrimer}>
              {order.id}
            </InterfaceText>
          </div>
        </div>
      )}
      {!compact && (
        <div className="space-y-rhythm0 border-b border-primary px-4 py-rhythm1 lg:px-8 xl:flex xl:items-baseline xl:justify-between xl:space-y-0">
          <div className="flex sm:items-baseline sm:space-x-4">
            <Heading withoutSpacing level={2} mobileSize={TextSize.GreatPrimer}>
              {createdDate}
            </Heading>
          </div>
          <div>
            <InterfaceText size={TextSize.LongPrimer}>Ref: </InterfaceText>
            <InterfaceText italic size={TextSize.LongPrimer}>
              {order.id}
            </InterfaceText>
          </div>
        </div>
      )}

      <div className="space-y-rhythm3 px-4 py-rhythm2 lg:px-8">
        {/* Billing */}
        {!compact && (
          <div className="space-y-rhythm0 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:space-y-0">
            {order.deliveryAddress && (
              <UserAddress
                className="col-span-6 xl:col-span-5"
                address={order.deliveryAddress}
                title="Delivery address"
              />
            )}
            {order.billingAddress ? (
              <UserAddress
                className="col-span-6 xl:col-span-5"
                address={order.billingAddress}
                title="Billing address"
              />
            ) : (
              <dl className="col-span-6 xl:col-span-5">
                <InterfaceText as="dt" bold>
                  Billing address
                </InterfaceText>
                <dd className="text-gray-500">
                  <InterfaceText className="block">
                    Same as Delivery Address
                  </InterfaceText>
                </dd>
              </dl>
            )}
          </div>
        )}

        <div>
          {!compact && (
            <div className="mb-rhythm2 border-b border-primary xl:mb-rhythm3">
              <Heading level={3} withoutSpacing mobileSize={TextSize.Pica}>
                Items
              </Heading>
            </div>
          )}

          <div className="space-y-rhythm4 xl:grid xl:grid-cols-2 xl:space-x-8 xl:space-y-0">
            <CompleteOrderItems discount={order.discount} items={order.items} />

            <div className="items-end lg:flex">
              <OrderAmounts
                amounts={order.amount}
                discount={order.discount}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
