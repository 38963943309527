import { InterfaceText, TextSize } from 'components/atoms/typography';
import { assertUnreachable, makeClassNames } from 'lib/util';

import React from 'react';
import { useCustomer } from 'service/hooks/session';

type Size = 'm' | 'lg';

type Props = PropsWithClassName & {
  size?: Size;
};

function dimensions(size: Size): [string[], TextSize] {
  switch (size) {
    case 'm':
      return [['h-5', 'w-5', 'p-4'], TextSize.BodyCopy];
    case 'lg':
      return [['h-10', 'w-10', 'p-8'], TextSize.DoublePica];

    default:
      assertUnreachable(size);
  }
}

const UserInitial: React.FC<Props> = ({ className, size = 'm' }) => {
  const { data: user } = useCustomer();

  if (!user) {
    return null;
  }

  const userInitial = (user.firstName || user.email)[0];

  const [sizeClassNames, textSize] = dimensions(size);

  return (
    <span
      className={makeClassNames(
        className,
        'flex items-center justify-center rounded-full bg-primary',
        sizeClassNames
      )}
    >
      <InterfaceText color="text-secondary-200" size={textSize}>
        {userInitial.toUpperCase()}
      </InterfaceText>
    </span>
  );
};

export default UserInitial;
