import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';
import { useQuery } from '@svelte/reactify/useQuery';

export type UserOrderHistory = NonNullable<
  GqlSchemaWorker.OrdersQuery['currentUser']['orders']
>;

export function useUserOrders() {
  const worker = useGraphqlWorker();

  return useQuery({
    key: 'orders',
    variables: {},
    worker
  });
}
